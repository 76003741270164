<template>
  <router-view></router-view>
  <div class="hidden sm:block">
    <Debug />
  </div>
</template>

<script>
import Debug from "./components/Library/Debug.vue";

export default {
  components: {
    Debug,
  },
};
</script>
