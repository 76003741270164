<template>
  <ProjectSlider
    ref="updateProjectSlider"
    type="updateProject"
    :project-id="$route.params.projectId"
    @update-project="updateProjectHandler"
  ></ProjectSlider>

  <div class="mb-5 flex justify-between sm:items-center md:flex-row">
    <h3 class="text-lg leading-6 font-medium text-gray-900">
      {{ project?.name }}
    </h3>

    <MenuVue
      v-if="canUpdateProject"
      as="div"
      class="relative inline-block text-left"
    >
      <div>
        <MenuButton
          class="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
        >
          <span class="sr-only">Open options</span>
          <EllipsisVerticalIcon class="h-5 w-5" aria-hidden="true" />
        </MenuButton>
      </div>

      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems
          class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
        >
          <div class="py-1">
            <MenuItem v-slot="{ active }">
              <a
                href="#"
                :class="[
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block px-4 py-2 text-sm',
                ]"
                @click="$refs.updateProjectSlider.open()"
                >Edit Project
              </a>
            </MenuItem>
            <MenuItem v-slot="{ active }">
              <a
                href="#"
                :class="[
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block px-4 py-2 text-sm',
                ]"
                @click="openProjectDeleteModal"
                >Delete Project
              </a>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </MenuVue>
  </div>

  <div v-if="loadingReplays">
    <div class="p-40">
      <Spinner />
    </div>
  </div>

  <div
    class="flex flex-col h-96 justify-center"
    v-else-if="!replays.length && !loadingReplays"
  >
    <div class="text-center">
      <ExclamationCircleIcon class="mx-auto h-12 w-12 text-gray-400" />
      <h3 class="mt-2 text-sm font-medium text-gray-900">No clips!</h3>
      <p class="mt-1 text-sm text-gray-500">
        Publish a Dash to this project and it will appear here!
      </p>
      <div class="mt-6">
        <a target="_blank" href="https://dashcam.io/download">
          <button
            type="button"
            class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <ArrowDownTrayIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            Download Dashcam
          </button>
        </a>
      </div>
    </div>
  </div>
  <ul
    role="list"
    class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
    v-else
  >
    <li
      v-for="replay in replays"
      :key="replay.id"
      class="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200"
    >
      <div class="relative">
        <router-link :to="{ name: 'replay', params: { replayId: replay.id } }">
          <img
            :src="replayGifUrl(replay)"
            alt="Dash Preview"
            class="w-full rounded-t-lg"
            :onerror="(event) => onImageLoadError(replay, event)"
          />

          <span v-if="replay.errorText" class="absolute top-2 right-2">
            <font-awesome-icon
              icon="fa-solid fa-bug"
              class="text-red-500 w-5"
            />
          </span>

          <span
            class="absolute bottom-1 left-1 bg-white rounded px-0.5 py-px text-xs opacity-50"
          >
            {{ replayDurationDisplay(replay.duration) }}
          </span>
        </router-link>
      </div>
      <div class="w-full flex items-center justify-between p-4 space-x-6">
        <div class="flex-1 truncate">
          <div class="flex items-center space-x-3">
            <h3 class="text-gray-900 text-sm font-medium truncate">
              {{ replay.title }}
            </h3>
          </div>
          <p class="mt-1 text-gray-500 text-sm truncate">
            {{ dateDisplay(replay.createdAt) }}
          </p>
        </div>
        <img
          class="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
          :src="replay.user.profile.picture"
          alt=""
        />
      </div>
      <div class="p-4 flex relative">
        <p class="flex-1">
          <ApplicationTag
            v-for="app in replay.apps"
            :key="app"
            :text="app"
            class="mr-2"
            :icon="
              replay.icons
                ? replay.icons.find((icon) => icon.name === app)?.url
                : null
            "
          />
        </p>
        <CommandLineIcon class="h-6 w-6 mt-1" v-if="replay.logs?.length" />
      </div>
    </li>
  </ul>
  <TransitionRoot as="template" :show="showProjectDeleteModal">
    <DialogVue
      as="div"
      class="fixed z-10 inset-0 overflow-y-auto"
      @close="open = false"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          >
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div class="sm:flex sm:items-start">
                <div
                  class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
                >
                  <ExclamationTriangleIcon
                    class="h-6 w-6 text-red-600"
                    aria-hidden="true"
                  />
                </div>
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <DialogTitle
                    as="h3"
                    class="text-lg leading-6 font-medium text-gray-900"
                  >
                    Delete Project
                  </DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">
                      Are you sure you want to delete this project? All
                      associated clips will be removed. This action cannot be
                      undone.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse"
            >
              <button
                type="button"
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                @click="deleteProject"
              >
                Delete
              </button>
              <button
                type="button"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                @click="closeProjectDeleteModal"
              >
                Cancel
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </DialogVue>
  </TransitionRoot>
</template>

<script>
import moment from "moment";
import { ref } from "vue";
import { mapMutations, mapState } from "vuex";
import {
  CommandLineIcon,
  EllipsisVerticalIcon,
  ExclamationTriangleIcon,
} from "@heroicons/vue/20/solid";
import {
  ExclamationCircleIcon,
  ArrowDownTrayIcon,
} from "@heroicons/vue/24/outline";
import {
  Dialog as DialogVue,
  DialogOverlay,
  DialogTitle,
  Menu as MenuVue,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { datadogLogs as log } from "@datadog/browser-logs";

import ProjectSlider from "../../../components/Library/ProjectSlider.vue";
import Spinner from "../../../components/Library/Spinner.vue";
import ApplicationTag from "../../../components/Library/ApplicationTag.vue";
import EnsureCloud from "../../../helpers/ensure-cloud";

export default {
  components: {
    DialogVue,
    DialogOverlay,
    DialogTitle,
    EllipsisVerticalIcon,
    ExclamationTriangleIcon,
    ExclamationCircleIcon,
    ArrowDownTrayIcon,
    ApplicationTag,
    Spinner,
    ProjectSlider,
    MenuVue,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    CommandLineIcon,
  },
  setup() {
    const page = ref(1);
    const allReplaysLoaded = ref(false);
    const showProjectDeleteModal = ref(false);

    return {
      allReplaysLoaded,
      showProjectDeleteModal,
      page,
    };
  },
  data() {
    return {
      loadingReplays: true,
      replays: [],
      project: undefined,
    };
  },
  computed: {
    ...mapState(["user"]),
    canUpdateProject() {
      return (
        this.user?.id === this.project?.user?.id || this.user?.role === "owner"
      );
    },
  },
  watch: {
    $route(to, from) {
      const previousProjectId = from?.params.projectId;
      const newProjectId = to?.params?.projectId;
      const isReplaysRoute = to?.name === "replays";
      const isNewProject = newProjectId !== previousProjectId;

      if (isReplaysRoute && isNewProject) {
        this.loadingReplays = true;
        // Reset page when switching between projects
        this.page = 1;
        this.load();
      }
    },
  },
  async mounted() {
    EnsureCloud();
    this.load();
    this.debouncedFetchReplays = _.debounce(this.getNextPage, 300);
    window.addEventListener("scroll", this.debouncedFetchReplays);
  },
  unmounted() {
    this.debouncedFetchReplays?.cancel();
  },
  methods: {
    closeProjectDeleteModal() {
      this.showProjectDeleteModal = false;
    },
    openProjectDeleteModal() {
      this.showProjectDeleteModal = true;
    },
    async load() {
      const { projectId } = this.$route.params;
      this.project = this.$store.getters.getProjectById(projectId);
      this.replays = await Cloud.replaysGet({
        project: projectId,
        status: "published",
        page: this.page,
      })
        .headers({
          Authorization: `Bearer ${this.$store.state.token}`,
        })
        .tolerate((err) => {
          console.error(err);
        });
      this.loadingReplays = false;
    },
    async getNextPage() {
      if (this.allReplaysLoaded) return;

      const bottomWindowDiff =
        document.documentElement.offsetHeight -
        (document.documentElement.scrollTop + window.innerHeight);
      // If within 200px of the bottom of the page, load the next page
      const bottomOfWindow = bottomWindowDiff <= 200;

      if (bottomOfWindow) {
        const { projectId } = this.$route.params;
        this.page += 1;
        const newPage = this.page;
        const newReplays = await Cloud.replaysGet({
          project: projectId,
          status: "published",
          page: newPage,
        })
          .headers({
            Authorization: `Bearer ${this.$store.state.token}`,
          })
          .tolerate((err) => {
            console.error(err);
          });

        if (newReplays.length === 0) {
          // No more data to load
          this.allReplaysLoaded = true;
        } else {
          this.replays.push(...newReplays);
        }
      }
    },
    dateDisplay(date) {
      return moment(date).calendar(null, {
        lastDay: "[Yesterday at] LT",
        sameDay: "[Today at] LT",
        lastWeek: "dddd [at] LT",
        nextWeek: "dddd [at] LT",
        sameElse: "L",
      });
    },
    replayDurationDisplay(duration) {
      return `${moment(duration).minutes().toString().padStart(2, 0)}:${moment(
        duration
      )
        .seconds()
        .toString()
        .padStart(2, 0)}`;
    },
    onImageLoadError(replay, event) {
      log.logger.info("Failed to load GIF for Replay: ", {
        replay: _.pick(replay, ["title", "id"]),
      });
      if (event.target.currentSrc.includes("screenshot")) {
        log.logger.info("Failed to load GIF and screenshot for Replay: ", {
          replay: _.pick(replay, ["title", "id"]),
        });
      } else {
        // eslint-disable-next-line no-param-reassign
        event.target.src = this.replayScreenshotUrl(replay);
      }
    },
    replayGifUrl(replay) {
      return `${import.meta.env.VITE_API_ENDPOINT}/replay/${
        replay.id
      }/gif?shareKey=${replay.shareKey}`;
    },
    replayScreenshotUrl(replay) {
      return `${import.meta.env.VITE_API_ENDPOINT}/replay/${
        replay.id
      }/screenshot?shareKey=${replay.shareKey}`;
    },
    updateProjectHandler(updatedProject) {
      this.updateProject(updatedProject);
      this.project = updatedProject;
    },
    async deleteProject() {
      this.closeProjectDeleteModal();

      const { projectId } = this.$route.params;
      await Cloud.projectDelete({ id: projectId })
        .headers({
          Authorization: `Bearer ${this.$store.state.token}`,
        })
        .switch({
          error: (err) => {
            log.logger.error("Project Delete error", err);
            this.$toast.open({
              message: "There was a problem deleting the project",
              type: "error",
            });
          },
          success: () => {
            this.$toast.open({
              message: "Project Deleted",
              type: "success",
            });

            this.$router.push({
              name: "projects",
            });
          },
        });
    },
    ...mapMutations(["updateProject"]),
  },
};
</script>
