import { datadogLogs as log } from "@datadog/browser-logs";

log.init({
  clientToken: "pub88ce8b67efeb9de03f00860713fb38ea",
  forwardErrorsToLogs: true,
  sampleRate: 100,
  service: "web",
  trackInteractions: true,
});
log.logger.setHandler(["http", "console"]);

log.addLoggerGlobalContext("env", import.meta.env.CONTEXT);
log.addLoggerGlobalContext("version", import.meta.env.COMMIT_REF);
log.addLoggerGlobalContext("branch", import.meta.env.BRANCH);
