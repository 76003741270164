<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div
    aria-live="assertive"
    class="fixed inset-0 flex items-end pointer-events-none sm:items-start"
  >
    <div
      class="w-full flex flex-col items-center space-y-4 absolute bottom-5 right-5"
    >
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="show"
          class="max-w-xs w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
        >
          <div class="p-2 px-3">
            <div class="flex items-center">
              <div class="w-0 flex-1 flex justify-between">
                <p class="w-0 flex-1 rose prose-indigo text-gray-500 text-sms">
                  <span class="text-xs"
                    >Version #<a
                      class="font-medium"
                      :href="
                        'https://github.com/replayableio/web/commit/' + version
                      "
                      >{{ version.substring(0, 7) }}</a
                    >
                    in {{ environment }}.
                  </span>
                </p>
              </div>
              <div class="ml-4 flex-shrink-0 flex">
                <button
                  @click="show = false"
                  class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { XMarkIcon } from "@heroicons/vue/20/solid";

const { BRANCH } = import.meta.env;
const VERSION = import.meta.env.COMMIT_REF;
const ENVIRONMENT = import.meta.env.CONTEXT;

export default {
  components: {
    XMarkIcon,
  },
  setup() {
    const show = ref(false);
    // if (ENVIRONMENT === "production") show = ref(false);
    return {
      show,
    };
  },
  data() {
    return {
      environment: ENVIRONMENT,
      version: VERSION,
      branch: BRANCH,
    };
  },
};
</script>
