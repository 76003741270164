<template>
  <div />
</template>

<script>
import { defineComponent } from "vue";
import { mapActions } from "vuex";

export default defineComponent({
  components: {},
  inject: ["auth"],
  created() {
    if (this.$store.state.user) {
      // If the user is already logged in, call api to check if the invite code is valid
      this.checkInvite(this.$route.query.code);
      this.$router.push("/");
    } else {
      // If not, set the beta code in the store and sign up
      this.$store.commit("setTeamInviteCode", this.$route.query.code);
      this.auth.loginWithRedirect({ screen_hint: "signup" });
    }
  },
  methods: {
    ...mapActions(["checkInvite"]),
  },
});
</script>
