<!-- eslint-disable max-len -->
<template>
  <div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
    <Spinner v-if="isAuthing" class="mt-10" />
    <div class="sm:mx-auto sm:w-full sm:max-w-md" v-if="!isAuthing">
      <img
        class="mx-auto h-12 w-auto"
        src="/images/icon.png"
        alt="Replayable Icon"
      />
      <h2
        class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900"
      >
        You're on the waitlist!
      </h2>
      <p class="mt-2 text-center text-lg text-gray-600">
        Enter a beta code below to try Dashcam now.
      </p>
    </div>

    <div
      v-if="!isAuthing"
      class="mt-8 sm:mx-auto sm:w-full sm:max-w-md"
      v-cloak
    >
      <div
        class="bg-white py-8 px-4 shadow sm:rounded-lg heap- join-discord-click sm:px-10"
      >
        <form class="space-y-5">
          <div>
            <label for="email" class="block text-sm font-medium text-gray-700">
              Beta Code
            </label>
            <div class="mt-1">
              <input
                id="beta-code"
                v-model.trim="inputBetaCode"
                name="beta-code"
                type="text"
                class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                :class="{ 'border-red-500': hasBetaCodeError }"
              />
              <p
                v-if="hasBetaCodeError"
                class="text-red-500 text-xs italic pt-2"
              >
                Please enter a valid beta code.
              </p>
            </div>
          </div>

          <div>
            <button
              v-if="!isLoading"
              type="submit"
              class="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              @click.prevent="checkBetaWithLoading(inputBetaCode)"
            >
              <span>Enter Beta</span>
            </button>
            <Spinner v-if="isLoading" />
          </div>
        </form>
      </div>
      <div class="rounded-md bg-yellow-50 p-4 mt-4">
        <div class="flex">
          <div class="flex-shrink-0">
            <ExclamationTriangleIcon
              class="h-5 w-5 text-yellow-400"
              aria-hidden="true"
            />
          </div>
          <div class="ml-3">
            <h3 class="text-md font-medium text-yellow-800">
              Need a beta code?
            </h3>
            <div class="mt-2 text-sm text-yellow-700">
              <p>
                PM <code><strong>orlie</strong></code> in
                <a href="https://discord.gg/cWDFW8DzPm">our Discord</a> to get
                one.
              </p>

              <div class="mt-3">
                <a
                  href="https://discord.gg/cWDFW8DzPm"
                  target="_blank"
                  rel="noreferrer"
                  class="heap-join-discord-click inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                >
                  Join Discord
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions, mapState } from "vuex";

import Spinner from "../../components/Library/Spinner.vue";
import { ExclamationTriangleIcon } from "@heroicons/vue/20/solid";

export default defineComponent({
  inject: ["auth"],
  components: {
    Spinner,
    ExclamationTriangleIcon,
  },
  async created() {
    if (this.$route.query.code) {
      this.$store.commit("setBetaCode", this.$route.query.code);
    }

    if (!this.$store.state.user) {
      // user loads the page without an account
      this.auth.loginWithRedirect({ screen_hint: "signup" });
    } else {
      // user loads the page after already having a code
      if (this.$store.state.user?.isBeta) {
        this.$router.push("/projects");
      } else if (this.betaCode) {
        this.isAuthing = true;
        await this.checkBetaWithLoading(this.betaCode);
        this.isAuthing = false;
      }
    }
    setTimeout(() => {
      this.isAuthing = false;
    }, 3000);
  },
  data: () => ({
    inputBetaCode: "",
    isLoading: false,
    isAuthing: true,
  }),
  computed: {
    ...mapState(["token", "hasBetaCodeError", "betaCode"]),
  },
  watch: {
    betaCode(newBetaCode) {
      this.inputBetaCode = newBetaCode;
    },
  },
  methods: {
    async checkBetaWithLoading(inputBetaCode) {
      this.isLoading = true;
      let valid = await this.checkBeta(inputBetaCode);
      if (valid) {
        this.$router.push("/welcome");
      }
      this.isLoading = false;
    },
    ...mapActions(["checkBeta"]),
  },
});
</script>
