<template><div></div></template>

<script>
export default {
  mounted() {
    this.finishAtlassian(this.$route.query.code);
  },
  methods: {
    finishAtlassian(authCode) {
      window.opener.postMessage({
        type: "atlassian-callback",
        authCode,
      });
      window.close();
    },
  },
};
</script>
