<template>
  <div class="flex justify-center items-center h-screen">
    <div v-if="isDownloading">
      <div class="p-16 flex flex-col items-center">
        <p class="text-sm font-medium text-gray-900 p-8">Downloading Build</p>
        <Spinner />
      </div>
    </div>

    <div v-else-if="isFetchingBuild">
      <div class="p-16 flex flex-col items-center">
        <p class="text-sm font-medium text-gray-900 p-8">Fetching Build...</p>
        <Spinner />
      </div>
    </div>

    <div v-else-if="hasError">
      <div role="alert">
        <div class="bg-red-500 text-white font-bold rounded-t px-4 py-2">
          Error
        </div>
        <div
          class="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700"
        >
          <p>Could not download build. Please refresh and try again</p>
        </div>
      </div>
    </div>

    <div v-else class="max-w-3xl mx-auto">
      <a
        class="inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        :href="downloadURL"
      >
        <ComputerDesktopIcon class="mr-3 -ml-1 h-5 w-5" aria-hidden="true" />
        {{ build.name }}
      </a>
    </div>
  </div>
</template>

<script>
import { ComputerDesktopIcon } from "@heroicons/vue/24/outline";
import { defineComponent } from "vue";

import Spinner from "../components/Library/Spinner.vue";

export default defineComponent({
  components: {
    ComputerDesktopIcon,
    Spinner,
  },
  data() {
    return {
      build: null,
      isDownloading: false,
      isFetchingBuild: false,
      hasError: false,
    };
  },
  computed: {
    downloadURL() {
      const version = this.$route.query.version.replace(/\/$/, "");
      const { platform } = this.$route.query;
      return `${
        import.meta.env.VITE_API_ENDPOINT
      }/api/v1/download?platform=${platform}&version=${version}`;
    },
  },
  created() {
    const availablePlatforms = [
      {
        name: "Download Mac build",
        platform: "mac",
      },
      {
        name: "Download Windows build",
        platform: "win",
      },
    ];

    this.build = _.find(
      availablePlatforms,
      (build) => build.platform === this.$route.query.platform
    );
  },
});
</script>
