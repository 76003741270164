<template>
  <div class="fixed left-0 top-0 right-0 bottom-0 overflow-y-auto bg-gray-100">
    <div class="flex h-screen" v-if="isLoading">
      <div class="m-auto">
        <Spinner />
      </div>
    </div>
    <div v-if="!isLoading">
      <div
        class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8"
        v-if="onboardingPage === 'team'"
      >
        <div class="sm:mx-auto sm:w-full sm:max-w-md mb-8">
          <img
            class="mx-auto h-12 w-auto"
            src="/images/icon.png"
            alt="Dashcam Icon"
          />
          <h2
            class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900"
          >
            Team Setup
          </h2>
          <p class="mt-2 text-center text-sm text-gray-600">
            Take a minute to name your team and invite your teammates.
          </p>
        </div>

        <Team />

        <div class="mt-8 sm:mx-auto sm:w-full">
          <div class="mt-10">
            <div class="relative mb-10">
              <div class="absolute inset-0 flex items-center">
                <div class="w-full border-t border-gray-300" />
              </div>
              <div class="relative flex justify-center text-sm">
                <span class="bg-gray-100 px-2 text-gray-500"
                  >Ready to go!?</span
                >
              </div>
            </div>
            <a
              href="#"
              @click.prevent="setOnboard()"
              class="heap-continue-workspace-click flex w-full items-center justify-center rounded-md border border-indigo-300 bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700"
              >Continue</a
            >
          </div>
        </div>
      </div>
      <Download v-else />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions, mapMutations, mapState } from "vuex";
// import { datadogLogs as log } from "@datadog/browser-logs";

import Spinner from "../../components/Library/Spinner.vue";
import Download from "./Download.vue";
import Team from "../Team.vue";

export default defineComponent({
  components: {
    Download,
    Team,
    Spinner,
  },
  inject: ["auth"],
  data() {
    return {
      onboardingPage: "download",
      isLoading: true,
    };
  },
  computed: {
    ...mapState(["user", "teamInviteCode"]),
  },
  watch: {
    user(newUser) {
      if (newUser.team) {
        this.selectOnboardingPage(newUser);
      }
    },
  },
  async created() {
    // Clear beta code error on load
    if (this.user && !this.user.hasOnboarded && this.teamInviteCode) {
      await this.checkInvite(this.teamInviteCode);
    }

    this.selectOnboardingPage(this.user);

    window.analytics?.track("Signup", {
      branch: import.meta.env.BRANCH,
      code: this.$route.query.betacode,
    });

    // eslint-disable-next-line no-undef
    rdt("track", "SignUp");

    // eslint-disable-next-line no-undef
    gtag("event", "conversion", {
      send_to: "AW-749090699/yqV1CK6J8oAYEIvvmOUC",
    });

    // eslint-disable-next-line no-undef
    qp("track", "CompleteRegistration");

    this.isLoading = false;
  },
  methods: {
    ...mapActions(["checkInvite", "setHasOnboarded"]),
    ...mapMutations(["clearTeamInviteCode"]),
    selectOnboardingPage(user) {
      if (user.role !== "owner") {
        this.onboardingPage = "download";
        this.clearTeamInviteCode();
      } else if (!user.hasOnboarded) {
        this.onboardingPage = "download";
      } else {
        this.onboardingPage = "download";
      }
    },
    async setOnboard() {
      await this.setHasOnboarded();
      this.onboardingPage = "download";
    },
  },
});
</script>
