<template>
  <ProjectSlider
    v-if="projectListPage"
    ref="newProjectSlider"
    type="newProject"
    @new-project="fetchProjects"
  ></ProjectSlider>

  <div
    v-if="projectListPage"
    class="mb-5 flex justify-between sm:items-center md:flex-row"
  >
    <h3 class="text-lg leading-6 font-medium text-gray-900">Projects</h3>

    <div
      class="my-1.5 flex justify-center space-x-6 text-sm"
      v-if="loadedProjectList"
    >
      <div class="mt-3 flex sm:mt-0 sm:ml-4">
        <button
          @click="$refs.newProjectSlider.open()"
          type="button"
          class="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          New Project
        </button>
      </div>
    </div>
  </div>

  <div v-if="loadingProjects && projectListPage">
    <div class="p-40">
      <Spinner />
    </div>
  </div>

  <div
    v-if="loadedProjectList"
    class="bg-white shadow overflow-hidden sm:rounded-md mb-6"
  >
    <ul role="list" class="divide-y divide-gray-200">
      <!-- eslint-disable-next-line vue/no-template-shadow -->
      <li v-for="project in projects" :key="project.id">
        <router-link
          :to="{ name: 'replays', params: { projectId: project.id } }"
          class="block hover:bg-gray-50"
        >
          <div class="px-4 py-4 flex items-center sm:px-6">
            <div
              class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between"
            >
              <div class="truncate">
                <div class="flex text-sm">
                  <p class="font-medium text-indigo-600 truncate">
                    {{ project.name }}
                  </p>
                </div>
                <div class="mt-2 flex">
                  <div class="flex items-center text-sm text-gray-500">
                    <CalendarIcon
                      class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <p>
                      Created
                      {{ " " }}
                      <time>{{ dateDisplay(project.createdAt) }}</time>
                    </p>
                  </div>
                </div>
              </div>
              <div class="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                <div class="flex overflow-hidden -space-x-1">
                  <img
                    class="inline-block h-6 w-6 rounded-full ring-2 ring-white"
                    :src="project.user.profile.picture"
                  />
                </div>
              </div>
            </div>
            <div class="ml-5 flex-shrink-0">
              <ChevronRightIcon
                class="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
          </div>
        </router-link>
      </li>
    </ul>
  </div>
  <router-view></router-view>
</template>
<script>
import moment from "moment";
import { datadogLogs as log } from "@datadog/browser-logs";
import { mapState, mapActions, mapMutations } from "vuex";
import { CalendarIcon, ChevronRightIcon } from "@heroicons/vue/20/solid";

import ProjectSlider from "../../components/Library/ProjectSlider.vue";
import Spinner from "../../components/Library/Spinner.vue";

export default {
  components: {
    CalendarIcon,
    ChevronRightIcon,
    ProjectSlider,
    Spinner,
  },
  inject: ["auth"],
  data() {
    return {};
  },
  computed: {
    project() {
      return this.projects.find((e) => e.id === this.$route.params.projectId);
    },
    projectListPage() {
      return !this.$route.params.projectId;
    },
    loadedProjectList() {
      return !this.loadingProjects && this.projectListPage;
    },
    ...mapState(["projects", "loadingProjects"]),
  },
  watch: {
    async $route(to) {
      if (to?.name === "projects") {
        await this.fetchProjects();
      }
    },
  },
  async mounted() {
    window.analytics?.page();
    await this.fetchProjects();
    log.logger.info("Project Page Loaded");
  },
  created() {
    // if (!this.$store.state.user.isBeta) {
    //   this.$router.push({
    //     name: "beta",
    //   });
    // }
  },
  methods: {
    dateDisplay(date) {
      return moment(date).calendar(null, {
        lastDay: "[yesterday at] LT",
        sameDay: "[today at] LT",
        lastWeek: "dddd [at] LT",
        nextWeek: "dddd [at] LT",
        sameElse: "L",
      });
    },
    updateProjectHandler(updatedProject) {
      this.updateProject(updatedProject);
    },
    ...mapActions(["fetchProjects"]),
    ...mapMutations(["updateProject"]),
  },
};
</script>
