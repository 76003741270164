/* eslint-disable no-param-reassign */
// vuex.vuejs.org/guide/#the-simplest-store
import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import { datadogLogs as log } from "@datadog/browser-logs";

import EnsureCloud from "./helpers/ensure-cloud";

const platform = () => {
  if (window.navigator.userAgent.indexOf("Windows") !== -1) {
    return "Windows";
  }
  if (window.navigator.userAgent.indexOf("Mac") !== -1) {
    return "Mac";
  }
  if (window.navigator.userAgent.indexOf("X11") !== -1) {
    return "Unix";
  }
  if (window.navigator.userAgent.indexOf("Linux") !== -1) {
    return "Linux";
  }
  return "";
};

export default createStore({
  state() {
    return {
      betaCode: null,
      teamInviteCode: null,
      hasBetaCodeError: false,
      user: null,
      token: null,
      loadingProjects: false,
      projects: [],
      platform: platform(),
    };
  },
  mutations: {
    setUser: (state, user) => {
      state.user = user;
    },
    setToken: (state, token) => {
      state.token = token;
    },
    clearUser: (state) => {
      state.user = null;
    },
    clearToken: (state) => {
      state.token = null;
    },
    setProjects: (state, projects) => {
      state.projects = projects;
    },
    updateProject: (state, updatedProject) => {
      const projectIdx = _.findIndex(state.projects, {
        id: updatedProject.id,
      });
      if (projectIdx !== -1) {
        state.projects[projectIdx] = updatedProject;
      }
    },
    setLoadingProjects: (state) => {
      state.loadingProjects = true;
    },
    setCompleteLoadingProjects: (state) => {
      state.loadingProjects = false;
    },
    resetApp: (state) => {
      state.user = null;
      state.token = null;
      state.projects = [];
      state.loadingProjects = false;
      state.hasBetaCodeError = false;
    },
    setBetaCode: (state, betaCode) => {
      state.betaCode = betaCode;
    },
    clearBetaCode: (state) => {
      state.betaCode = "";
    },
    setTeamInviteCode: (state, teamInviteCode) => {
      state.teamInviteCode = teamInviteCode;
    },
    clearTeamInviteCode: (state) => {
      state.teamInviteCode = "";
    },
    setBetaCodeError: (state) => {
      state.hasBetaCodeError = true;
    },
    clearBetaCodeError: (state) => {
      state.hasBetaCodeError = false;
    },
  },
  getters: {
    getProjectById: (state) => (projectId) =>
      state.projects.find((project) => project.id === projectId),
  },
  actions: {
    async fetchProjects({ commit, state }) {
      await EnsureCloud();

      commit("setLoadingProjects");

      const projects = await Cloud.projectsGet({
        team: state.user.team.id,
      })
        .headers({
          Authorization: `Bearer ${state.token}`,
        })
        .tolerate((err) => {
          console.error(err);
        });

      if (Array.isArray(projects)) {
        commit("setProjects", projects);
        commit("setCompleteLoadingProjects");
      }
    },
    async checkInvite({ commit, state }, inviteCode) {
      await EnsureCloud();

      try {
        const updatedUser = await Cloud.validateInvite({
          code: inviteCode,
        })
          .headers({
            Authorization: `Bearer ${state.token}`,
          })
          .tolerate((err) => {
            log.logger.error(err);
          });

        if (updatedUser) {
          commit("setUser", updatedUser);
        }
      } catch (e) {
        log.logger.error(e);
      }
    },
    async checkBeta({ commit, state }, betaCode) {
      await EnsureCloud();

      try {
        const updatedUser = await Cloud.validateBeta({
          code: betaCode,
        })
          .headers({
            Authorization: `Bearer ${state.token}`,
          })
          .tolerate((err) => {
            log.logger.error(err);
            commit("setBetaCodeError");
          });

        if (updatedUser) {
          commit("setUser", updatedUser);
        }

        return updatedUser;
      } catch (e) {
        log.logger.error(e);
        return false;
      }
    },
    async editTeamName({ commit, state }, teamName) {
      await EnsureCloud();

      const updatedUser = await Cloud.teamEditName({
        name: teamName,
      })
        .headers({
          Authorization: `Bearer ${state.token}`,
        })
        .tolerate((err) => {
          log.logger.error(err);
          commit("setBetaCodeError");
        });

      if (updatedUser) {
        commit("setUser", updatedUser);
      }
    },
    async setHasOnboarded({ commit, state }) {
      await EnsureCloud();

      const updatedUser = await Cloud.userSetOnboard()
        .headers({
          Authorization: `Bearer ${state.token}`,
        })
        .tolerate((err) => {
          log.logger.error(err);
        });

      if (updatedUser) {
        commit("setUser", updatedUser);
      }
    },
  },
  plugins: !window.location.href.endsWith("embed=true")
    ? [
        createPersistedState({
          key: "replayable",
        }),
      ]
    : [],
});
