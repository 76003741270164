<template>
  <div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img
        class="mx-auto h-12 w-auto"
        src="/images/icon.png"
        alt="Dashcam Icon"
      />
      <h2
        class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900"
      >
        Get Dashcam
      </h2>
      <p class="mt-2 text-center text-sm text-gray-600">
        Detect, Debug, Resolve
      </p>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <div>
          <div class="mt-6 flow-root">
            <ul role="list" class="-my-5 divide-y divide-gray-200">
              <li class="pb-5">
                <div class="space-y-5">
                  <h2 class="text-lg font-semibold text-gray-800">
                    Download the Desktop App
                  </h2>
                  <p class="my-2 text-sm text-gray-600 line-clamp-2">
                    You'll need the Dashcam desktop app to create and share your
                    first Dash. It's a quick, secure, and free!
                  </p>
                  <a
                    v-if="platformWithFallback !== 'Mac'"
                    :href="downloadLink"
                    target="_blank"
                    rel="noreferrer"
                    class="heap-download-link block text-center w-full rounded-md border border-transparent bg-indigo-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Download for {{ platformWithFallback }}
                  </a>
                  <div v-else class="flex">
                    <a
                      :href="downloadLink"
                      target="_blank"
                      rel="noreferrer"
                      class="heap-download-link block text-center w-full rounded-md border border-transparent bg-indigo-600 py-2 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mr-2"
                    >
                      <font-awesome-icon
                        class="w-5 h-5 mt-0.5 text-white"
                        icon="fa-brands fa-apple"
                      />
                      Mac (Intel)
                    </a>
                    <a
                      :href="`${downloadLink}?build=arm`"
                      target="_blank"
                      rel="noreferrer"
                      class="heap-download-link block text-center w-full rounded-md border border-transparent bg-indigo-600 py-2 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ml-2"
                    >
                      <font-awesome-icon
                        class="w-5 h-5 mt-0.5 text-white"
                        icon="fa-brands fa-apple"
                      />
                      Mac (Silicon)
                    </a>
                  </div>
                </div>
              </li>
              <Disclosure as="li" class="pt-5 pb-5" v-slot="{ open }">
                <dt class="text-md heap-expand-chrome-extension">
                  <DisclosureButton
                    class="flex w-full items-start justify-between text-left text-gray-400"
                  >
                    <span class="font-medium text-gray-900"
                      ><span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-gray-200 uppercase last:mr-0 mr-1"
                      >
                        Optional
                      </span>
                      Add to Chrome
                    </span>
                    <span class="ml-6 flex h-7 items-center">
                      <ChevronDownIcon
                        :class="[
                          open ? '-rotate-180' : 'rotate-0',
                          'h-6 w-6 transform',
                        ]"
                        aria-hidden="true"
                      />
                    </span>
                  </DisclosureButton>
                </dt>
                <DisclosurePanel as="dd" class="mt-2">
                  <div class="space-y-5">
                    <p class="mt-1 text-sm text-gray-600 line-clamp-2">
                      Capture debugging info from your browser such as logs and
                      network requests with Dashcam's Chrome Extension.
                    </p>
                    <div class="mx-auto max-w-4xl my-5">
                      <img src="/images/logs-web.png" class="rounded shadow" />
                    </div>
                    <a
                      target="_blank"
                      href="https://chrome.google.com/webstore/detail/dashcam/dkcoeknmlfnfimigfagbcjgpokhdcbbp"
                      type="button"
                      class="heap-visit-client-chrome w-full text-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      :class="[
                        isChrome
                          ? 'bg-indigo-600 hover:bg-indigo-700'
                          : 'bg-gray-500 hover:bg-gray-500',
                      ]"
                    >
                      Add to Chrome
                    </a>
                  </div>
                </DisclosurePanel>
              </Disclosure>
              <Disclosure as="li" class="pt-5 pb-5" v-slot="{ open }">
                <dt class="text-md heap-expand-devtools">
                  <DisclosureButton
                    class="flex w-full items-start justify-between text-left text-gray-400"
                  >
                    <span class="font-medium text-gray-900"
                      ><span
                        class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-gray-600 bg-gray-200 uppercase last:mr-0 mr-1"
                      >
                        Optional
                      </span>
                      Install CLI
                    </span>
                    <span class="ml-6 flex h-7 items-center">
                      <ChevronDownIcon
                        :class="[
                          open ? '-rotate-180' : 'rotate-0',
                          'h-6 w-6 transform',
                        ]"
                        aria-hidden="true"
                      />
                    </span>
                  </DisclosureButton>
                </dt>
                <DisclosurePanel as="dd" class="mt-2">
                  <div class="space-y-5">
                    <p class="mt-1 text-sm text-gray-600 line-clamp-2">
                      Capture terminal output with Dashcam CLI so it's
                      synchronized with your video.
                    </p>

                    <div class="mx-auto max-w-4xl my-5">
                      <img src="/images/logs-cli.png" class="rounded shadow" />
                    </div>
                    <a
                      href="https://docs.dashcam.io/replayable/log-and-error-tracking/nodejs"
                      target="_blank"
                      rel="noreferrer"
                      class="heap-visit-client-cli block text-center w-full rounded-md border border-transparent bg-indigo-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Read the Docs
                    </a>
                  </div>
                </DisclosurePanel>
              </Disclosure>
            </ul>
          </div>
        </div>
      </div>

      <div class="mt-10">
        <div class="relative mb-10">
          <div class="absolute inset-0 flex items-center">
            <div class="w-full border-t border-gray-300" />
          </div>
          <div class="relative flex justify-center text-sm">
            <span class="bg-gray-100 px-2 text-gray-500"
              >Got everything installed!?</span
            >
          </div>
        </div>
        <a
          href="/"
          @click.prevent="setOnboard()"
          class="heap-continue-workspace-click flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
          >Continue to your workspace</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { defineComponent } from "vue";
import { mapActions, mapState } from "vuex";
import { ChevronDownIcon } from "@heroicons/vue/24/outline";

export default defineComponent({
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronDownIcon,
  },
  computed: {
    ...mapState(["platform"]),
    platformWithFallback() {
      return this.platform || "Desktop";
    },
    downloadLink() {
      switch (this.platform) {
        case "Mac":
          return "https://api.testdriver.ai/download/mac";
        case "Windows":
          return "https://api.testdriver.ai/download/win";
        case "Linux":
        case "Unix":
          return "https://airtable.com/shrtQn9Ky0rzHpzM6";
        default:
          return "https://docs.dashcam.io/replayable/setup/download-replayable";
      }
    },
    isChrome() {
      return window.navigator.userAgent.indexOf("Chrome") !== -1;
    },
  },
  methods: {
    ...mapActions(["checkInvite", "setHasOnboarded"]),
    async setOnboard() {
      await this.setHasOnboarded();
      this.$router.push("/");
    },
  },
});
</script>
